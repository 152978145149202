import Vue from 'vue';
import App from './App.vue';
import router from './router'
import VueWechatTitle from 'vue-wechat-title'
import Header from './components/header'
import Footer from './components/footer'
import Vant from 'vant';
import 'vant/lib/index.css';
import { Toast } from 'vant';



import {
	hex_md5
} from './assets/js/md5.js'

Vue.config.productionTip = false
Vue.use(VueWechatTitle)
Vue.use(Vant);
Vue.use(Toast);
Vue.component("header-view", Header);
Vue.component("footer-view", Footer);

Vue.prototype.$md5 = hex_md5

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')