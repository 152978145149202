<template>
	<div class="header">
		<div class="top-img-box"><img @click="toIndex" src="../assets/img/logo2.png" alt="" class="top-logo">
			<img @click="changeList"
				:src="showList?require('../assets/img/list2.png'):require('../assets/img/list.png')" alt=""
				class="top-list-img">
		</div>
		<div :class="['top-list',showList?'top-list-show':'']">
			<div @click="toUrl(item)" class="top-list-item" v-for="item in list" :key="item.id">{{item.text}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				showList: false,
				list: [{
					id: 0,
					text: 'Twitter',
					web: 'https://x.com/heardly_app'
				}, {
					id: 1,
					text: 'Youtube',
					web: 'https://www.youtube.com/channel/UCE_EJQRor4gV4g6XVtfGhTA'
				}, {
					id: 2,
					text: 'Facebook',
					web: 'https://www.facebook.com/profile.php?id=61551819697587'
				}, {
					id: 3,
					text: 'About',
					url: '/about'
				}, {
					id: 4,
					text: 'Download',
					web: '/getSystem'
				}]
			}
		},
		methods: {
			changeList() {
				this.showList = !this.showList
			},
			toUrl(item) {
				if (item.url) {
					this.$router.push({
						path: item.url
					})
				}
				if (item.web) {
					window.location.href = item.web
				}
			},
			toIndex() {
				this.$router.push({
					path: '/'
				})
			}
		},
		mounted() {

		}
	}
</script>

<style lang="less" scoped>
	.header {
		background: #FFFFFF;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		z-index: 2;
		height: 87px;

		.top-img-box {
			padding: 18px 25px;
			box-shadow: 1px 1px 3px rgba(0, 0, 0, .06);
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			background: #fff;
			z-index: 2;
			box-sizing: border-box;
		}

		.top-logo {
			height: 48px;
			width: auto;
			cursor: pointer;
		}

		.top-list-img {
			height: 48px;
			width: 48px;
			float: right;
			cursor: pointer;
		}

		.top-list {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			background: #fff;
			padding: 18px 0;
			transition: bottom .3s linear;
			z-index: 1;

			.top-list-item {
				text-align: center;
				font-size: 16px;
				line-height: 40px;
				cursor: pointer;
			}

			.top-list-item:active {
				background: #f4f4f4;
			}
		}

		.top-list-show {
			bottom: -236px;
		}
	}
</style>