import Vue from 'vue'
import Router from 'vue-router'

const Home = () =>
	import('@/views/Home')
const About = () =>
	import('@/views/about')
const Fuwu = () =>
	import('@/views/privacy/fuwu')
const Yinsi = () =>
	import('@/views/privacy/yinsi')
const GetSystem = () =>
	import('@/views/getSystem')
const GetApp = () =>
	import('@/views/getApp')
const GetSystem2 = () =>
	import('@/views/getSystem2')
const Applink = () =>
	import('@/views/applink')
const Android = () =>
	import('@/views/Android')
const Dl = () =>
	import('@/views/dl')
const Ratings = () =>
	import('@/views/ratings')
Vue.use(Router)


export default new Router({
	base: '/HeardLy/',
	mode: 'history',
	scrollBehavior(to, from, savedPosition) { // 跳转页面默认保持滚动顶部 (只在支持 history.pushState 的浏览器中可用。)
		return {
			x: 0,
			y: 0
		}
	},
	routes: [{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'Heardly: Best Book SummariesDaily 2024',
			description: 'HeardLy',
			keywords: 'HeardLy'
		}
	}, {
		path: '/about',
		name: 'About',
		component: About,
		meta: {
			title: 'Heardly: Best Book SummariesDaily 2024',
			description: 'HeardLy',
			keywords: 'HeardLy'
		}
	}, {
		path: '/service',
		name: 'Fuwu',
		component: Fuwu,
		meta: {
			title: 'HeardLy Terms of Service',
			description: 'HeardLy',
			keywords: 'HeardLy'
		}
	}, {
		path: '/privacy',
		name: 'Yinsi',
		component: Yinsi,
		meta: {
			title: 'Privacy Policy',
			description: 'HeardLy',
			keywords: 'HeardLy'
		}
	}, {
		path: '/getSystem',
		name: 'GetSystem',
		component: GetSystem,
		meta: {
			title: 'Heardly: Best Book SummariesDaily 2024',
			description: 'HeardLy',
			keywords: 'HeardLy'
		}
	}, {
		path: '/getApp',
		name: 'GetApp',
		component: GetApp,
		meta: {
			title: 'Heardly: Best Book SummariesDaily 2024',
			description: 'HeardLy',
			keywords: 'HeardLy'
		}
	}, {
		path: '/getSystem2',
		name: 'GetSystem2',
		component: GetSystem2,
		meta: {
			title: 'Heardly: Best Book SummariesDaily 2024',
			description: 'HeardLy',
			keywords: 'HeardLy'
		}
	}, {
		path: '/applink',
		name: 'Applink',
		component: Applink,
		meta: {
			title: 'Heardly: Best Book SummariesDaily 2024',
			description: 'HeardLy',
			keywords: 'HeardLy'
		}
	}, {
		path: '/Android',
		name: 'Android',
		component: Android,
		meta: {
			title: 'Heardly: Best Book SummariesDaily 2024',
			description: 'HeardLy',
			keywords: 'HeardLy'
		}
	}, {
		path: '/dl',
		name: 'Dl',
		component: Dl,
		meta: {
			title: 'Heardly: Best Book SummariesDaily 2024',
			description: 'HeardLy',
			keywords: 'HeardLy'
		}
	}, {
		path: '/ratings',
		name: 'Ratings',
		component: Ratings,
		meta: {
			title: 'Heardly: Best Book SummariesDaily 2024',
			description: 'HeardLy',
			keywords: 'HeardLy'
		}
	}]
})