<template>
	<div>
		<div class="line"></div>
		<div class="footer">
			<img src="../assets/img/footer.png" alt="" class="footer-top">
			<img src="../assets/img/footer2.png" alt="" class="footer-icon">
			<div class="footer-bottom">
				<div @click="toFuwu">HeardLy Terms of Service</div>
				<div @click="toYinsi">Privacy Policy</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		methods: {
			toFuwu() {
				this.$router.push({
					path: '/service'
				})
			},
			toYinsi() {
				this.$router.push({
					path: '/privacy'
				})
			}
		},
		mounted() {

		}
	}
</script>

<style lang="less" scoped>
	.line {
		height: 1px;
		background-color: #e4ebf3;
		margin: 0 1.25rem;
	}

	.footer {
		background: #fff;
		padding-top: 30px;
		padding-bottom: 0.5rem;

		.footer-top {
			width: 60px;
			height: 12px;
			margin: 0 auto;
			display: block;
		}

		.footer-icon {
			width: 24px;
			height: 12px;
			margin: 12px auto;
			display: block;
		}

		.footer-bottom {
			display: flex;
			padding-bottom: 20px;

			div {
				flex: 1;
				text-align: center;
			}
		}
	}
</style>